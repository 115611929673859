<engage-page-header></engage-page-header>
<div class="bg" [ngClass]="bgClass">
  <div class="container pt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <p>Upload identification document.</p>
        </div>
      </div>
      <div class="row" *ngIf="isiFrameURLReady">
        <div class="col">
          <iframe [src]="trustediFrameURL" height="510" width="100%"
            sandbox="allow-top-navigation allow-same-origin allow-scripts" allow="camera *;microphone *;geolocation *;clipboard-read *;clipboard-write *"></iframe>
        </div>
      </div>
      <div class="row" style="margin-top: 45px;">
        <div class="col mb-5">
          <button mat-raised-button (click)="proceed()" [disabled]="isProceedDisabled" class="primary">{{btnText}}</button>
        </div>
      </div>
    </div>
  </div>
</div>